import React, { useMemo } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/Layout/Layout"
import MasonryGrid from "../../components/MasonryGrid/MasonryGrid"
import Seo from "../../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import * as styles from "./Sale.module.scss"
import loadable from "@loadable/component"
import { enricheLotWithAuction, getAuctionsIds } from "../../utils/auction"

const LoadableSaleMonitor = loadable(
  () => import("../../components/GalleryMonitor/GalleryMonitor")
)

const Sale = ({ data }: PageProps<any>) => {
  const organizationId = useMemo(() => {
    return parseInt(process.env.GATSBY_MONEGRAPH_ORGANIZATION_ID as string)
  }, [])

  const auctions = useMemo(() => {
    return getAuctionsIds(data?.contentfulSale?.lots)
  }, [data])

  return (
    <>
      <Seo title="Sale" />
      <div className={styles.saleImgBox}>
        <GatsbyImage
          image={getImage(data?.contentfulSale?.mainMedia)}
          alt={data?.contentfulSale?.mainMedia.title}
          className={styles.saleImg}
        />
      </div>
      <div className={styles.saleInner}>
        <h1>{data.contentfulSale.name}</h1>
        <p>{data.contentfulSale.description.description}</p>
      </div>

      <LoadableSaleMonitor
        organizationId={organizationId}
        auctions={auctions}
        render={({ result }) => {
          const enrichedData = enricheLotWithAuction(
            data?.contentfulSale?.lots || [],
            result?.auctions
          )

          return <MasonryGrid data={enrichedData} />
        }}
      />
    </>
  )
}

export default Sale

export const saleQuery = graphql`
  query ($saleId: String!) {
    contentfulSale(contentful_id: { eq: $saleId }) {
      createdAt
      endDate
      name
      slug
      mainMedia {
        gatsbyImageData
        title
      }
      description {
        description
      }
      startDate
      endDate
      lots {
        estimate
        auctionId
        lotId
        additionalMedia {
          gatsbyImageData(placeholder: BLURRED)
          file {
            contentType
            url
          }
        }
        asset {
          ... on ContentfulNftAsset {
            id
            slug
            title
            artist {
              name
              slug
            }
            mainMedia {
              file {
                contentType
                url
              }
              gatsbyImageData
            }
          }
          ... on ContentfulPhysicalAsset {
            id
            slug
            title
            artist {
              name
              slug
            }
            mainMedia {
              file {
                contentType
                url
              }
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
